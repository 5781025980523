@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');

/* Base styles */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: black;
}

.logo img {
  width: 200px;
  height: 100px; /* Adjust as per your logo size */
}

.navbar {
  display: flex;
}

.dropdown {
  position: relative;
  margin-right: 20px;
}

.dropbtn {
font-family: "DM Sans", sans-serif;
  background-color: #000000;
  color: rgb(255, 255, 255);
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.dropbtn a:hover {
  color: #FFD966;
  font-weight: bold;
}

.dropbtn a {
  color: white;
  text-decoration: none;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #FFD966;
  width: 230px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  color: #fff;
  text-decoration: none;
  text-align: left;
}

.dropdown-content a {
  color: rgb(0, 0, 0);
  padding: 10px 14px;
  display: block;
font-family: "DM Sans", sans-serif;
text-decoration: none;
}

.dropdown-content a:hover {
  background-color: #ffffff;
  font-weight: bold;
}

.dropdown:hover .dropdown-content {
  display: block;
  color: #ffffff;
}

.dropButton {
  background-color: #6402ee;
font-family: "DM Sans", sans-serif;
  color: rgb(255, 255, 255);
  padding: 12px 24px; /* Add padding */
  font-size: 15px;
  border: none;
  cursor: pointer;
  border-radius: 8px; /* Add border radius */
  transition: background-color rgb(255, 255, 255) 0.3s;
}

.dropButton a {
  text-decoration: none;
  color: white;
}

.dropButton a:hover {
  text-decoration: none;
  color: #000000;
  background-color: #FFD966;
}

.dropButton:hover {
font-family: "DM Sans", sans-serif;
  font-weight: bold;
  background-color: #FFD966;
}

/* Media Queries */

.menu-button {
  display: none; /* Initially hide the menu button */
}

/* Styles for menu button lines */
.menu-button .bar {
  width: 25px;
  height: 3px;
  background-color: #fff;
  margin: 4px 0;
}

/* Media Queries */
@media screen and (max-width: 600px) {
  
  .dropdown-content {
    display: none;
  }

  /* Show the navbar in a column layout */
  .navbar.show {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  /* Show the menu button on smaller screens */
  .menu-button {
    display: block;
    cursor: pointer;
  }

  /* Style adjustments for the navbar */
  .navbar {
    display: none;
    position: absolute;
    top: 20%;
    left: 0;
    width: 100%;
    background-color: black;
  }


}
@media screen and (max-width: 400px) {
  .logo img {
    width: 150px; /* Decrease logo size for smaller screens */
  }

  .dropbtn {
    padding: 8px 16px; /* Decrease padding for dropdown buttons */
    font-size: 14px; /* Decrease font size for dropdown buttons */
  }

  .dropdown-content a {
    padding: 8px 12px; /* Decrease padding for dropdown links */
    font-size: 14px; /* Decrease font size for dropdown links */
  }

  .dropButton {
    padding: 10px 20px; /* Decrease padding for dropButton */
    font-size: 14px; /* Decrease font size for dropButton */
  }

  .menu-button .bar {
    width: 20px; /* Decrease width of menu button lines */
  }
}
