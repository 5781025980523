@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');

#solution {
    background-color: black;
    background-size: cover;
    background-position: center;
    height: auto;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.dropbtn a:hover {
    color: #FFD966;
    font-weight: bold;
  }
  
  .dropBtn a {
    color: white;
    text-decoration: none;
  }

#solution h2 {
    text-align: center;
    margin: 0;
    line-height: 2;
    font-size: 40px;
font-family: "DM Sans", sans-serif;
}

.products-container {
    display: flex;
    flex-wrap: wrap; /* Allow flex items to wrap to the next line */
    align-items: center;
    justify-content: center;
    text-align: center;
}

.text-column,
.image-column {
    flex: 1;
    padding: 20px;
font-family: "DM Sans", sans-serif;
}

.text-column .Details {
    font-size: 30px; /* Adjust font size for smaller screens */
}

.text-column .intro {
    font-size: 17px; /* Adjust font size for smaller screens */
    font-weight: 100;
    line-height: 1.5;
    font-family: "Barlow", sans-serif;
}

.image-column img {
    max-width: 70%; /* Ensure image doesn't overflow its container */
    height: auto;
    border-radius: 50%;
    top: 0;
    left: 0;
    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
    
}

.dropBtn {
    background-color: #6402ee;
font-family: "DM Sans", sans-serif;
    color: rgb(255, 255, 255);
    padding: 12px 24px;
    font-size: 15px;
    border: none;
    margin-top: 20px;
    cursor: pointer;
    border-radius: 8px;
    transition: background-color 0.3s;
}

.dropBtn:hover {
    color: #000000;
    background-color: #FFD966;
font-family: "DM Sans", sans-serif;
    font-weight: bold;
}

/* Media Queries */
@media screen and (max-width: 768px) {
    #solution h2 {
        font-size: 30px; /* Decrease font size for smaller screens */
        margin-bottom: 30px; /* Add spacing between heading and content */
    }

    .text-column,
    .image-column {
        flex: 100%; /* Make columns full width on smaller screens */
        padding: 10px; /* Adjust padding for smaller screens */
    }

    .text-column .Details {
        font-size: 25px; /* Decrease font size for smaller screens */
    }

    .text-column .intro {
        font-size: 14px; /* Decrease font size for smaller screens */
    }

    .image-column img {
        max-width: 80%; /* Ensure image fits within smaller screens */
        border-radius: 50%; /* Maintain border-radius for circular image */
       /* Remove clip-path on smaller screens */
    }

    .dropBtn {
        margin-top: 10px; /* Adjust margin-top for smaller screens */
        font-size: 13px; /* Decrease font size for smaller screens */
    }
}

@media screen and (max-width: 400px) {
    .text-column .Details {
        font-size: 20px; /* Further decrease font size for very small screens */
    }

    .text-column .intro {
        font-size: 12px; /* Further decrease font size for very small screens */
    }
}
