/* PortalData.css */

/* Importing font */
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');

#dataPortal {
    background-color: black;
    background-size: cover;
    background-position: center;
    height: auto;
    color: #fff;
    padding: 50px 0;
}

.Data {
    text-align: center;
    margin: 0;
    line-height: 2;
    font-size: 40px;
    margin-bottom: 40px;
    font-family: "DM Sans", sans-serif;
}

.data-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.text-column-data,
.image-column-data {
    flex: 1;
    padding: 20px;
    font-family: "DM Sans", sans-serif;
}

.text-column-data .DetailsData {
    font-size: 30px;
    font-family: "DM Sans", sans-serif;
}

.text-column-data .introData {
    font-size: 17px;
    font-weight: 100;
    line-height: 1.5;
    font-family: "Barlow", sans-serif;
}

.image-column-data img {
    max-width: 100%;
    height: auto;
    clip-path: polygon(50% 0%, 100% 0%, 100% 50%, 50% 50%, 50% 100%, 0% 100%, 0% 50%, 50% 50%);
}

.dropBtnData {
    background-color: #6402ee;
    font-family: "DM Sans", sans-serif;
    color: rgb(255, 255, 255);
    padding: 12px 24px;
    font-size: 15px;
    border: none;
    margin-top: 20px;
    cursor: pointer;
    border-radius: 8px;
    transition: background-color 0.3s;
}

.dropBtnData a:hover {
    color: #000000;
    font-weight: bold;
}

.dropBtnData a {
    color: white;
    text-decoration: none;
}

.dropBtnData:hover {
    color: #000000;
    background-color: #FFD966;
    font-family: "DM Sans", sans-serif;
    font-weight: bold;
}

/* Media Queries */
@media screen and (max-width: 768px) {
    .Data {
        font-size: 30px;
    }

    .text-column-data,
    .image-column-data {
        flex: 100%;
        padding: 10px;
        max-width: calc(100% - 20px);
    }

    .text-column-data .DetailsData {
        font-size: 25px;
    }

    .text-column-data .introData {
        font-size: 14px;
    }
}
