/* Default styles */

.footer {
  background-color: #000000;
  height: auto; /* Change height to auto to accommodate content */
  color: #fff;
  padding: 20px;
  display: flex;
  flex-direction: column; /* Adjust to column layout on smaller screens */
  justify-content: flex-start;
  align-items: center; /* Align items to center vertically */
}

.footer__logo img {
  width: 250px;
  padding-top: 20px; /* Adjust padding for logo */
}

.footer__columns {
  display: flex;
  flex-direction: column; /* Adjust to column layout on smaller screens */
  justify-content: center;
  align-items: center;
  padding: 20px; /* Adjust padding for columns */
}



.footer__column h3 {
  font-size: 20px; /* Adjust font size for heading */
  margin-bottom: 10px; /* Adjust margin for heading */
  font-family: "DM Sans", sans-serif;
}
.footer__column ul {
  padding-left: 0; /* Remove default padding */
  list-style: none; /* Remove default list styles */
}
.footer__column ul li {
  margin-bottom: 5px; /* Adjust margin for list items */
  font-family: "Barlow", sans-serif;
  font-weight: 200;
  text-align:start;

}

.icons a {
  margin-right: 10px;
  font-size: 25px;
 /* Adjust font size for icons */
}
.footer__column a {
  color: #FFD966; /* Change color to yellow */
  text-decoration: none; /* Remove text decoration */
}

.footer__column a:hover {
  color: white; /* Change color on hover */
}

/* Responsive styles */

@media screen and (min-width: 768px) {
  .footer {
    height: 40vh; /* Adjust height for larger screens */
    flex-direction: row; /* Revert to row layout for larger screens */
    justify-content: space-between;
  }

  .footer__columns {
    flex-direction: row; /* Revert to row layout for larger screens */
    padding: 30px;
  }

  .footer__column {
    margin-bottom: 0; /* Reset margin for columns on larger screens */
    margin-right: 20px; /* Adjust margin for columns on larger screens */
  }

  .footer__column:last-child {
    margin-right: 0; /* Remove right margin for last column on larger screens */
  }

  .footer__column h3 {
    font-size: 25px; /* Adjust font size for heading on larger screens */
  }

  .footer__column ul li {
    font-size: 17px; /* Adjust font size for list items on larger screens */
  }
}
@media screen and (max-width: 768px) {
  .footer__logo img {
    width: 250px; /* Increase the size of the logo */
    padding-top: 50px; /* Adjust padding to move the logo down */
  }
  .footer__columns {
    flex-direction: row;
    flex-wrap: wrap; /* Allow columns to wrap to the next row */
  }

  .footer__column {
    flex: 1; /* Each column occupies equal space */
   padding: 12px;
  } 
}
