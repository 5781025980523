@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');

#panel {
    background-color: black;
    background-size: cover;
    background-position: center;
    height: auto; /* Remove fixed height */
     /* Set minimum height to ensure content visibility */
    color: #fff;
    padding: 50px 0; /* Add padding for better spacing */
}

.PanelHeading{
    text-align: center;
    margin: 0;
    line-height: 2;
    font-size: 40px;
    margin-bottom: 40px;
   font-family: "DM Sans", sans-serif;
}
.panel-book{
    display: flex;
    justify-content: center;
    align-items: center;
}

.panel-container {
    display: flex;
    flex-wrap: wrap; /* Allow columns to wrap to the next line */
    align-items: center;
    justify-content: space-between;
    padding: 40px 80px;
   
    text-align: center;
}
.panel-container .block{
    border: 2px solid white;
    padding: 20px 40px;
    width: 20%;
    height: 100%;
    transition: transform 0.3s ease-in-out;
    font-family: "Barlow", sans-serif;
}

.fa-icon{
    font-size: 25px;
}
.panel-container .block:hover{
    transform: scale(1.1); /* Enlarge the column on hover */
    cursor: pointer;
}

.BtnPanel {
    background-color: #6402ee;
   font-family: "DM Sans", sans-serif;
    color: rgb(255, 255, 255);
    padding: 12px 24px;
    font-size: 15px;
    border: none;
    margin-top: 20px;
    cursor: pointer;
    border-radius: 8px;
    transition: background-color 0.3s;
}
.BtnPanel:hover {
    background-color: #FFD966;
    font-weight: bold;

  }
  
  .BtnPanel a {
    color: #ffffff;
    text-decoration: none;
  }



/* Media Queries */
@media screen and (max-width: 768px) {
    .PanelHeading {
        font-size: 30px; /* Decrease font size for smaller screens */
    }
    .panel-container .block {
        width: 100%; /* Full width for smaller screens */
        margin-bottom: 20px; 
    }
}
