/* Body.css */

/* Importing font */
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');

.body {
    background-color: black;
    background-size: cover;
    background-position: center;
    color: #fff;
    padding: 50px;
    min-height: 80vh; /* Ensure a minimum height for the body */
    display: flex;
    align-items: center;
    justify-content: center;
}

.content {
    max-width: 800px;
    margin: 0 auto;
    margin-top: 0;
    text-align: center;
}

.content img {
    width: 100%;
    max-width: 600px; /* Limit the maximum width of the image */
    height: auto;
}

.desc {
    font-size: 17px;
    font-weight: 100;
    color: #fff;
    font-family: "Barlow", sans-serif;
    font-display: swap;
}

/* Media Queries */
@media screen and (max-width: 768px) {
    .body {
        padding: 30px;
    }
    .content img {
        max-width: 100%; /* Ensure the image fits within smaller screens */
    }
    .desc {
        font-size: 13px;
    }
}

@media screen and (max-width: 400px) {
    .desc {
        font-size: 12px; /* Further decrease font size for very small screens */
    }
}
