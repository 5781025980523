/* BrandConnect.css */

/* Importing font */
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');

#brand {
    background-color: black;
    background-size: cover;
    background-position: center;
    height: auto; /* Remove fixed height */
    color: #fff;
    padding: 50px 0; /* Add padding for better spacing */
}
#quality{
    background-color: black;
    background-size: cover;
    background-position: center;
    height: auto; /* Remove fixed height */
    color: #fff;
    padding: 50px 0;
}

.connect {
    margin: 0;
    font-size: 40px;
    margin-bottom: 50px;
    font-family: "DM Sans", sans-serif;
    text-align: center; /* Center align the heading */
}

.products-contain {
    display: flex;
    flex-wrap: wrap; /* Allow columns to wrap to the next line */
    justify-content: center;
    max-width: 1200px;
    margin: 0 auto;
}

.column {
    flex: 1;
    padding: 20px;
    margin: 10px;
    text-align: center;
    background-color: #fff;
    border-radius: 8%;
    transition: transform 0.3s ease-in-out; /* Add transition for smooth effect */
    max-width: 300px; /* Limit maximum width of columns */
}

.column:hover {
    background-color: #bf92ff;
    transform: scale(1.1); /* Enlarge the column on hover */
    cursor: pointer;
}

.column h3 {
    color: #6402ee;
    font-size: 20px;
    margin-bottom: 10px;
    font-family: "DM Sans", sans-serif;
}

.column h3:hover {
    color: white;
}

.column p {
    font-size: 15px;
    line-height: 1.5;
    word-spacing: normal;
    margin-top: 20px;
    color: rgb(0, 0, 0);
    font-family: "Barlow", sans-serif;
}

@media screen and (max-width: 768px) {
    .column h3 {
        font-size: 22px; /* Adjust font size for smaller screens */
    }

    .column p {
        font-size: 14px; /* Adjust font size for smaller screens */
    }

    .connect {
        font-size: 30px; /* Adjust font size for smaller screens */
    }
}
