/* Contact.css */

/* Importing font */
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');

/* Global styles */
* {
    font-family: "DM Sans", sans-serif;
    box-sizing: border-box;
}

#contact {
    background-color: black;
    background-size: cover;
    background-position: center;
    height: auto;
    color: #fff;
    padding-top: 100px;
}

.HeadingContact {
    background-color: #000;
    color: white;
    text-align: center;
    font-size: 40px;
    font-family: "DM Sans", sans-serif;
}

/* Contact container style */
.contact-container {
    display: flex;
    flex-direction: column; /* Adjust for smaller screens */
    align-items: center;
    background-color: #000;
    padding: 50px 20px;
}

/* Form and image container styles */
.form-container,
.image-container {
    width: 100%;
    max-width: 800px;
    transition: transform 0.3s ease-in-out;
}

/* Form container style */
.form-container {
    margin-bottom: 30px;
}

/* Form style */
form {
    margin: 0 auto;
    padding: 0 20px;
}

/* Form group style */
.form-group {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Label style */
label {
    color: #fff;
    width: 30%;
    flex-shrink: 0;
    font-family: "DM Sans", sans-serif;
}

/* Input and textarea styles */
input[type="text"],
input[type="email"],
textarea {
    width: 70%;
    padding: 10px;
    border-radius: 5px;
    font-size: 15px;
    border: 1px solid #ccc;
    background-color: #fff;
    color: #000000;
    font-weight: 600;
    font-family: "DM Sans", sans-serif;
}

input[type="inquiry"] {
    width: 70%;
    padding: 10px;
    border-radius: 5px;
    font-size: 15px;
    border: 1px solid #ccc;
    background-color: #fff;
    color: #6402ee;
    font-weight: 600;
    font-family: "DM Sans", sans-serif;
}

/* Textarea style */
textarea {
    resize: vertical;
}

/* Button style */
.contactBtn {
    width: 70%;
    float: right;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #6402ee;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    font-family: "DM Sans", sans-serif;
}

/* Button hover style */
.contactBtn:hover {
    color: #000000;
    background-color: #ffd966;
}

/* Media query for larger screens */
@media screen and (min-width: 768px) {
    .contact-container {
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    .form-container {
        margin-right: 20px;
    }
    .HeadingContact {
        font-size: 27px;
        padding-top: 50px;
    }
}
